import grapesjs from "grapesjs";
export default grapesjs.plugins.add(
  "plugin-titleDesc-component",
  (editor, opts = {}) => {
    let componentOptions = opts;

    let pluginAttributes = {
      blocks: "plugin-titleDesc",

      // Default style
      defaultStyle: true,
      // Date input type, eg, 'date', 'datetime-local'
      dateInputType: "date",

      // Countdown class prefix
      pluginClssfx: "plugin-titleDesc",

      // Countdown label
      labelCountdown: "Countdown",

      // Countdown category label
      labelCountdownCategory: "Extra",

      // Days label text used in component
      labelDays: "days",

      // Hours label text used in component
      labelHours: "hours",

      // Minutes label text used in component
      labelMinutes: "minutes",

      // Seconds label text used in component
      labelSeconds: "seconds",
    };

    // Load defaults
    for (let name in pluginAttributes) {
      if (!(name in componentOptions))
        componentOptions[name] = pluginAttributes[name];
    }

    // Add components
    //  loadComponents(editor, componentOptions);

    //  // Add components
    // loadBlocks(editor, componentOptions);

    const domComponent = editor.DomComponents;
    const defaultType = domComponent.getType("default");
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const sfx = componentOptions.pluginClssfx;

    domComponent.addType(componentOptions.blocks, {
      model: defaultModel.extend(
        {
          defaults: Object.assign({}, defaultModel.prototype.defaults, {
            droppable: true,
            draggable: true,
            resizable: true,
            attributes: { 
              pluginTitle: 'TÍTULO DE LA PRESENTACIÓN',
              pluginDesc: 'lorem ipsum dolor sit amet, consectetur adip'
            },
            traits: [
              {
                label: "Titulo",
                name: "pluginTitle",
              },
              {
                label: "Descripción",
                name: "pluginDesc",
              }
            ]
          }),
        },
        {
          isComponent(el) {
            if (
              el.getAttribute &&
              el.getAttribute("data-gjs-type") == componentOptions.blocks
            ) {
              return { type: componentOptions.blocks };
            }
          },
        }
      ),

      view: defaultView.extend({
        init: function () {
          
        },
        onRender() {
          // What the user see in the canvas is totally up to you
          // it can be a simple image as a placeholder or
          // you can make it as much close to the original markup
          const attrs = this.model.getAttributes();
          const style = componentOptions.defaultStyle
          ? `<style>
          .${sfx} {
            position: relative;
            width: 100%;
            float: left;
            border: 3px solid #6500FF;
            height:100%;
            min-height: 125px;
          }
          .${sfx}-container {
            width: 100%;
            float: left;
          }
          .${sfx}-title {
            display: flex;
            float: left;
            min-width: 250px;
            width: 50%;
            height: 100%;
            padding: 0 15px;
            align-items: center;
            align-content: center;
            background-color: #6500FF;
          }
          .${sfx}-title h1 {
            color: #fff;
            font-size: 2.5rem;
            font-family: 'Bebas Neue';
            letter-spacing: 3px;
            margin: 0;
          }
          .${sfx}-desc {
            display: flex;
            float: left;
            min-width: 250px;
            width: 50%;
            height: 100%;
            padding: 0 15px;
            align-items: center;
            align-content: center;
            background-color: transparent;
            overflow: hidden;
          }
        </style>`
          : "";
          this.el.innerHTML = `${style}<div class="${sfx}">
            <div class="${sfx}-container">
              <div class="${sfx}-bottom-content">
                <div class="${sfx}-title">
                  <h1>${attrs['pluginTitle']}</h1>
                </div>
                <div class="${sfx}-desc">
                  ${
                    attrs['pluginDesc'] != '' ? `<p>${attrs['pluginDesc']}</p>` : ''
                  }
                </div>
              </div>
            </div>
          </div>`;
        },
        events: {
          // If you want to bind the event to children elements
          // 'click .someChildrenClass': 'methodName',
          // click: "handleClick",
          // dblclick: function () {
          //   alert("Hi!");
          // },
        }
      }),
    });

    var blockManager = editor.BlockManager;
    blockManager.add(componentOptions.blocks, {
      label: "Titulo y Descripción",
      category: "Extra",
      content: {
        content: '',
        editable: !1,
        droppable: !0,
        type: componentOptions.blocks,
        style: {
          width: "80%",
          border: "3px solid #6500FF"
        },
      },
      attributes: { class: "fa-regular fa-window-restore" },
    });
  }
);
