<template src="./Ovis.html">

</template>

<script>
import grapesjs from "grapesjs";
require("grapesjs/dist/css/grapes.min.css");
require("grapesjs-mjml");
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs/dist/grapes.min.js";
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css";
import "grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js";
import es from 'grapesjs/locale/es';
import ovisService from "@/services/Ovis";
import pluginExample from "@/plugins/grapesJS/pluginExample";
import pluginPresentation from "@/plugins/grapesJS/pluginPresentation";
import pluginLine from "@/plugins/grapesJS/pluginLine";
import pluginTimeLine from "@/plugins/grapesJS/pluginTimeLine";
import pluginTitle from "@/plugins/grapesJS/pluginTitle";
import pluginTitleDesc from "@/plugins/grapesJS/pluginTitleDesc";
import pluginIcon from "@/plugins/grapesJS/pluginIcon";
import { putFile } from "@/services/files";
export default {
    name: "Ovis",
    data() {
        return {
            id: this.$route.query.id,
            editor: null,
            component: null,
            ovis: null,
            ovisSelected: 0,
            modalCreationRequest: false,
            url: null
        };
    },
    mounted() {
        this.initGrapesjs();
    },
    created() {
        this.ovisGetData();
    },
    methods: {
        openModal() {
            this.modalCreationRequest = true;
        },
        async initGrapesjs() {
            this.editor = grapesjs.init({
                container: "#gjs",
                height: "100%",
                width: "100%",
                fromElement: true,
                dragMode: 'absolute',
                plugins: ["gjs-preset-webpage", pluginExample, pluginPresentation, pluginLine, pluginTimeLine, pluginTitle, pluginTitleDesc, pluginIcon],
                i18n: {
                    locale: 'es',
                    localeFallback: 'es',
                    messages: {es},
                    detectLocale: true,
                },
                canvas: {
                    scripts: [''],
                    styles: [
                        "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css",
                        "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"
                    ],
                },
                storageManager: {
                    id: "gjs-",
                    type: "local",
                    autosave: true,
                    storeComponents: true,
                    storeStyles: true,
                    storeHtml: true,
                    storeCss: true,
                },
                deviceManager: {
                    devices: [{
                        id: "desktop",
                        name: "Desktop",
                        width: "",
                    },
                    {
                        id: "tablet",
                        name: "Tablet",
                        width: "768px",
                        widthMedia: "992px",
                    },
                    {
                        id: "mobilePortrait",
                        name: "Mobile portrait",
                        width: "320px",
                        widthMedia: "575px",
                    },
                    ],
                },
                pluginsOpts: {
                    "grapesjs-preset-webpage": {
                        blocksBasicOpts: {
                            blocks: [
                                "column1",
                                "column2",
                                "column3",
                                "column3-7",
                                "text",
                                "link",
                                "image",
                                "video",
                            ],
                            flexGrid: 1,
                        },
                        blocks: ["link-block", "quote", "text-basic"],
                    },
                },
            });
        },
        async ovisGetData() {
            try {
                const response = await ovisService.getOvis(this.id);
                this.dataTemplate = response.data.dataTemplate;
                this.editor.setComponents(this.dataTemplate.pages[this.ovisSelected].html);
                this.editor.setStyle(this.dataTemplate.pages[this.ovisSelected].css);
                this.url = response.url;
                this.ovis = response.data;
                if (this.$store.state.Academic.ovi) {
                    const editOvi = this.$store.state.Academic.ovi;
                    this.ovis.name = editOvi.name;
                    this.ovis.courseId = editOvi.courseId;
                    this.ovis.module = editOvi.module;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectPage(index) {
            var html = this.editor.getHtml().toString();
            var css = this.editor.getCss().toString();
            var data = {
                html: html,
                css: css,
            };
            this.dataTemplate.pages[this.ovisSelected] = data;
            try {
                this.$store.dispatch('fetchLoading', true);
                const nameFile = `${Date.now()}.json`;
                const blob = new Blob([JSON.stringify(this.dataTemplate, null, 2)], {
                    type: "application/json",
                });
                await putFile(blob, "Ovis-process", nameFile);
                await ovisService.updateOvis({
                    id: this.id,
                    body: {
                        name: this.ovis.name,
                        courseId: this.ovis.courseId,
                        module: this.ovis.module,
                        nameFile: nameFile
                    },
                });
                await this.ovisGetData();
            } catch (error) {
                console.log(error);
            }
            this.editor.CssComposer.clear();
            this.editor.setComponents(this.dataTemplate.pages[index].html);
            this.editor.setStyle(this.dataTemplate.pages[index].css);
            this.ovisSelected = index;
        },
        async duplicatePage(index) {
            this.dataTemplate.pages.push(this.dataTemplate.pages[index]);
            try {
                this.$store.dispatch('fetchLoading', true);
                const nameFile = `${Date.now()}.json`;
                const blob = new Blob([JSON.stringify(this.dataTemplate, null, 2)], {
                    type: "application/json",
                });
                await putFile(blob, "Ovis-process", nameFile);
                await ovisService.updateOvis({
                    id: this.id,
                    body: {
                        name: this.ovis.name,
                        courseId: this.ovis.courseId,
                        module: this.ovis.module,
                        nameFile: nameFile
                    },
                });
                this.ovisSelected = this.ovis.dataTemplate.pages.length - 1;
                await this.ovisGetData();
            } catch (error) {
                console.log(error);
            }
        },
        async addPage() {
            this.dataTemplate.pages.push({
                html: `<body><div id="basicClass"></div></body>`,
                css: `#basicClass{width:500px;height:500px;}`,
            });
            try {
                this.$store.dispatch('fetchLoading', true);
                const nameFile = `${Date.now()}.json`;
                const blob = new Blob([JSON.stringify(this.dataTemplate, null, 2)], {
                    type: "application/json",
                });
                await putFile(blob, "Ovis-process", nameFile);
                await ovisService.updateOvis({
                    id: this.id,
                    body: {
                        name: this.ovis.name,
                        courseId: this.ovis.courseId,
                        module: this.ovis.module,
                        nameFile: nameFile
                    },
                });
                this.ovisSelected = this.ovis.dataTemplate.pages.length - 1;
                await this.ovisGetData();
            } catch (error) {
                console.log(error);
            }
        },
        async save(goTo) {
            var html = this.editor.getHtml().toString();
            var css = this.editor.getCss().toString();
            var data = {
                html: html,
                css: css,
            };
            this.dataTemplate.pages[this.ovisSelected] = data;
            try {
                this.$store.dispatch('fetchLoading', true);
                const nameFile = `${Date.now()}.json`;
                const blob = new Blob([JSON.stringify(this.dataTemplate, null, 2)], {
                    type: "application/json",
                });
                await putFile(blob, "Ovis-process", nameFile);
                await ovisService.updateOvis({
                    id: this.id,
                    body: {
                        name: this.ovis.name,
                        courseId: this.ovis.courseId,
                        module: this.ovis.module,
                        nameFile,
                    },
                });
                await this.ovisGetData();
            } catch (error) {
                console.log(error);
            }
            if (goTo) {
                this.$router.push('ovisAdmin');
            }
        },
        async deletePage(indexValue) {
            this.dataTemplate.pages.splice(indexValue, 1);
            try {
                this.$store.dispatch('fetchLoading', true);
                const nameFile = `${Date.now()}.json`;
                const blob = new Blob([JSON.stringify(this.dataTemplate, null, 2)], {
                    type: "application/json",
                });
                await putFile(blob, "Ovis-process", nameFile);
                await ovisService.updateOvis({
                    id: this.id,
                    body: {
                        name: this.ovis.name,
                        courseId: this.ovis.courseId,
                        module: this.ovis.module,
                        nameFile: nameFile,
                    },
                });
                await this.ovisGetData();
                this.editor.CssComposer.clear();
                this.editor.setComponents(this.dataTemplate.pages[0].html);
                this.editor.setStyle(this.dataTemplate.pages[0].css);
                this.ovisSelected = 0;
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped src="./Ovis.scss">

</style>
