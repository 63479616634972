import grapesjs from "grapesjs";
export default grapesjs.plugins.add(
  "plugin-icon-component",
  (editor, opts = {}) => {
    let componentOptions = opts;

    let pluginAttributes = {
      blocks: "plugin-icon",

      // Default style
      defaultStyle: true,
      // Date input type, eg, 'date', 'datetime-local'
      dateInputType: "date",

      // Countdown class prefix
      pluginClssfx: "plugin-icon",

      // Countdown label
      labelCountdown: "Countdown",

      // Countdown category label
      labelCountdownCategory: "Extra",

      // Days label text used in component
      labelDays: "days",

      // Hours label text used in component
      labelHours: "hours",

      // Minutes label text used in component
      labelMinutes: "minutes",

      // Seconds label text used in component
      labelSeconds: "seconds",
    };

    // Load defaults
    for (let name in pluginAttributes) {
      if (!(name in componentOptions))
        componentOptions[name] = pluginAttributes[name];
    }

    // Add components
    //  loadComponents(editor, componentOptions);

    //  // Add components
    // loadBlocks(editor, componentOptions);

    const domComponent = editor.DomComponents;
    const defaultType = domComponent.getType("default");
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const sfx = componentOptions.pluginClssfx;

    domComponent.addType(componentOptions.blocks, {
      model: defaultModel.extend(
        {
          defaults: Object.assign({}, defaultModel.prototype.defaults, {
            droppable: true,
            draggable: true,
            resizable: true,
            attributes: { 
              pluginIcon: 'fa-solid fa-graduation-cap'
            },
            traits: [
              {
                type: 'select',
                label: "Icono",
                name: "pluginIcon",
                options: [
                  { id: 'fa-solid fa-graduation-cap', name: 'graduation-cap'},
                  { id: 'fa-solid fa-chalkboard-user', name: 'chalkboard-user'},
                  { id: 'fa-solid fa-envelope', name: 'envelope'},
                  { id: 'fa-solid fa-file', name: 'file'},
                  { id: 'fa-solid fa-pen', name: 'pen'},
                  { id: 'fa-solid fa-book', name: 'book'},
                ]
              }
            ]
          }),
        },
        {
          isComponent(el) {
            if (
              el.getAttribute &&
              el.getAttribute("data-gjs-type") == componentOptions.blocks
            ) {
              return { type: componentOptions.blocks };
            }
          },
        }
      ),

      view: defaultView.extend({
        init: function () {
          
        },
        onRender() {
          // What the user see in the canvas is totally up to you
          // it can be a simple image as a placeholder or
          // you can make it as much close to the original markup
          const attrs = this.model.getAttributes();
          const style = componentOptions.defaultStyle
          ? `<style>
          .${sfx} {
            width: 100%;
          }

          .${sfx} i {
            font-size: 3rem;
            color: #000;
          }

        </style>`
          : "";
          this.el.innerHTML = `${style}<div class="${sfx}">
            <i class="${attrs['pluginIcon']}"></i>
          </div>`;
        },
        events: {
          // If you want to bind the event to children elements
          // 'click .someChildrenClass': 'methodName',
          // click: "handleClick",
          // dblclick: function () {
          //   alert("Hi!");
          // },
        }
      }),
    });

    var blockManager = editor.BlockManager;
    blockManager.add(componentOptions.blocks, {
      label: "Icono",
      category: "Extra",
      content: {
        content: '',
        editable: !1,
        droppable: !0,
        type: componentOptions.blocks,
        style: {
          width: "100px",
          height: "100px"
        },
      },
      attributes: { class: "fa-regular fa-window-restore" },
    });
  }
);
