import grapesjs from "grapesjs";
export default grapesjs.plugins.add(
  "plugin-timeline-component",
  (editor, opts = {}) => {
    let componentOptions = opts;

    let pluginAttributes = {
      blocks: "plugin-timeline",

      // Default style
      defaultStyle: true,
      // Date input type, eg, 'date', 'datetime-local'
      dateInputType: "date",

      // Countdown class prefix
      pluginClssfx: "plugin-timeline",

      // Countdown label
      labelCountdown: "Countdown",

      // Countdown category label
      labelCountdownCategory: "Extra",

      // Days label text used in component
      labelDays: "days",

      // Hours label text used in component
      labelHours: "hours",

      // Minutes label text used in component
      labelMinutes: "minutes",

      // Seconds label text used in component
      labelSeconds: "seconds",
    };

    // Load defaults
    for (let name in pluginAttributes) {
      if (!(name in componentOptions))
        componentOptions[name] = pluginAttributes[name];
    }

    // Add components
    //  loadComponents(editor, componentOptions);

    //  // Add components
    // loadBlocks(editor, componentOptions);

    const domComponent = editor.DomComponents;
    const defaultType = domComponent.getType("default");
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const sfx = componentOptions.pluginClssfx;

    domComponent.addType(componentOptions.blocks, {
      model: defaultModel.extend(
        {
          defaults: Object.assign({}, defaultModel.prototype.defaults, {
            droppable: true,
            draggable: true,
            resizable: false,
            attributes: { 
              timelineImage: '', 
              timelineTitle: 'lorem ipsum',
              timelineText: 'lorem ipsum dolor sit amet, consectetur adipiscing elements',
              timelineUp: false
            },
            traits: [
              {
                label: "Imagen",
                name: "timelineImage",
              },
              {
                label: "Titulo",
                name: "timelineTitle",
              },
              {
                label: "Texto",
                name: "timelineText",
              },
              {
                type: "checkbox",
                label: "Timeline UP",
                name: "timelineUp",
              }
            ]
          }),
        },
        {
          isComponent(el) {
            if (
              el.getAttribute &&
              el.getAttribute("data-gjs-type") == componentOptions.blocks
            ) {
              return { type: componentOptions.blocks };
            }
          },
        }
      ),

      view: defaultView.extend({
        init: function () {
          
        },
        onRender() {
          // What the user see in the canvas is totally up to you
          // it can be a simple image as a placeholder or
          // you can make it as much close to the original markup
          const attrs = this.model.getAttributes();
          const style = componentOptions.defaultStyle
          ? `<style>
          .${sfx} {
            width: 300px;
            display: block;
            position: relative;
            min-width: 300px;
          }
          .${sfx}-content {
            position: relative;
            border: 4px solid #ccc;
          }

          .${sfx}-content img {
            width: 100%;
          }

          .up hr {
            background: #424450;
            border: none;
            top: 8px;
            left: -120px;
            position: relative;
          }

          .down hr {
            background: #424450;
            border: none;
            top: -8px;
            left: -120px;
            position: relative;
          }

          .${sfx}-content-timeline {
            display: block;
            position: relative;
            min-height: 80px;
            margin: 0 auto;
            left: 0;
            right: 0;
            background: #fff;
            padding:20px;
          }

          .${sfx}-content-timeline h4 {
            color: #000;
            font-family: 'Bebas Neue';
            letter-spacing: 1px;
            margin: 0;
            text-transform: capitalize;
          }

          .${sfx}-content-timeline p {
            color: #000;
            font-family: 'Bebas Neue';
            letter-spacing: 1px;
            margin: 0;
          }

          .up .${sfx}-dot .circle{
            margin: 0 auto;
            top: 0;
            left: -241px;
            right: 0;
            width: 22px;
            height: 22px;
            background: #fff;
            border: 6px solid #424450;
            border-radius: 50%;
            display: block;
            position: absolute;
          }


          .down .${sfx}-dot .circle{
            margin: 0 auto;
            bottom: 0;
            left: -241px;
            right: 0;
            width: 22px;
            height: 22px;
            background: #fff;
            border: 6px solid #424450;
            border-radius: 50%;
            display: block;
            position: absolute;
          }
        </style>`
          : "";
          this.el.innerHTML = `${style}<div class="${sfx}">
            ${
              attrs['timelineUp'] ?
              `<div class="up">
                <hr width="4" size="90">
                <div class="${sfx}-dot">
                  <span class='circle'></span>
                </div>
              </div>` : ''
            }
            <div class='${sfx}-content'>
              ${
                attrs['timelineImage'] != '' ? `<img src="${attrs['timelineImage']}" />` : ''
              }
              <div class="${sfx}-content-timeline">
                <h4>${attrs['timelineTitle']}</h4>
                <p>${attrs['timelineText']}</p>
              </div>
            </div>
            ${
              !attrs['timelineUp'] ?
              `<div class="down">
                <hr width="4" size="90">
                <div class="${sfx}-dot">
                  <span class='circle'></span>
                </div>
              </div>` : ''
            }
          </div>`;
        },
        events: {
          // If you want to bind the event to children elements
          // 'click .someChildrenClass': 'methodName',
          // click: "handleClick",
          // dblclick: function () {
          //   alert("Hi!");
          // },
        }
      }),
    });

    var blockManager = editor.BlockManager;
    blockManager.add(componentOptions.blocks, {
      label: "TimeLine",
      category: "Extra",
      content: {
        content: '',
        editable: !1,
        droppable: !0,
        type: componentOptions.blocks,
        style: {
          width: "300px"
        },
      },
      attributes: { class: "fa-regular fa-list" },
    });
  }
);
