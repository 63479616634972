import grapesjs from "grapesjs";
export default grapesjs.plugins.add(
  "plugin-line-component",
  (editor, opts = {}) => {
    let componentOptions = opts;

    let pluginAttributes = {
      blocks: "plugin-line",

      // Default style
      defaultStyle: true,
      // Date input type, eg, 'date', 'datetime-local'
      dateInputType: "date",

      // Countdown class prefix
      pluginClssfx: "plugin-line",

      // Countdown label
      labelCountdown: "Countdown",

      // Countdown category label
      labelCountdownCategory: "Extra",

      // Days label text used in component
      labelDays: "days",

      // Hours label text used in component
      labelHours: "hours",

      // Minutes label text used in component
      labelMinutes: "minutes",

      // Seconds label text used in component
      labelSeconds: "seconds",
    };

    // Load defaults
    for (let name in pluginAttributes) {
      if (!(name in componentOptions))
        componentOptions[name] = pluginAttributes[name];
    }

    // Add components
    //  loadComponents(editor, componentOptions);

    //  // Add components
    // loadBlocks(editor, componentOptions);

    const domComponent = editor.DomComponents;
    const defaultType = domComponent.getType("default");
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const sfx = componentOptions.pluginClssfx;

    domComponent.addType(componentOptions.blocks, {
      model: defaultModel.extend(
        {
          defaults: Object.assign({}, defaultModel.prototype.defaults, {
            droppable: true,
            draggable: true,
            resizable: true
          }),
        },
        {
          isComponent(el) {
            if (
              el.getAttribute &&
              el.getAttribute("data-gjs-type") == componentOptions.blocks
            ) {
              return { type: componentOptions.blocks };
            }
          },
        }
      ),

      view: defaultView.extend({
        init: function () {
          
        },
        onRender() {
          // What the user see in the canvas is totally up to you
          // it can be a simple image as a placeholder or
          // you can make it as much close to the original markup
          const style = componentOptions.defaultStyle
          ? `<style>
          .${sfx}  {
            width: 100%;
          }
          .${sfx} hr {
            border-top: 2px solid #413E2D;
          }
        </style>`
          : "";
          this.el.innerHTML = `${style}<div class="${sfx}">
            <hr/>
          </div>`;
        },
        events: {
          // If you want to bind the event to children elements
          // 'click .someChildrenClass': 'methodName',
          // click: "handleClick",
          // dblclick: function () {
          //   alert("Hi!");
          // },
        }
      }),
    });

    var blockManager = editor.BlockManager;
    blockManager.add(componentOptions.blocks, {
      label: "Linea",
      category: "Extra",
      content: {
        content: '',
        editable: !1,
        droppable: !0,
        type: componentOptions.blocks,
        style: {
          width: "50%"
        },
      },
      attributes: { class: "fa-solid fa-minus" },
    });
  }
);
