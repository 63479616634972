import grapesjs from "grapesjs";
export default grapesjs.plugins.add(
  "plugin-example-component",
  (editor, opts = {}) => {
    let componentOptions = opts;

    let pluginAttributes = {
      blocks: "plugin-example",

      // Default style
      defaultStyle: true,
      // Date input type, eg, 'date', 'datetime-local'
      dateInputType: "date",

      // Countdown class prefix
      pluginClssfx: "plugin-example",

      // Countdown label
      labelCountdown: "Countdown",

      // Countdown category label
      labelCountdownCategory: "Extra",

      // Days label text used in component
      labelDays: "days",

      // Hours label text used in component
      labelHours: "hours",

      // Minutes label text used in component
      labelMinutes: "minutes",

      // Seconds label text used in component
      labelSeconds: "seconds",
    };

    // Load defaults
    for (let name in pluginAttributes) {
      if (!(name in componentOptions))
        componentOptions[name] = pluginAttributes[name];
    }

    // Add components
    //  loadComponents(editor, componentOptions);

    //  // Add components
    // loadBlocks(editor, componentOptions);

    const domComponent = editor.DomComponents;
    const defaultType = domComponent.getType("default");
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const sfx = componentOptions.pluginClssfx;

    domComponent.addType(componentOptions.blocks, {
      model: defaultModel.extend(
        {
          defaults: Object.assign({}, defaultModel.prototype.defaults, {
            droppable: false,
            traits: [
              {
                label: "Facebook URL",
                name: "facebook_url",
              },
              {
                label: "Animaciones",
                name: "animations",
                type: "select",
                // ...
                options: [
                  // Array of options
                  { id: "fa-bus-school", name: "bus-school" },
                  { id: "fa-bus-school", name: "bus-school" },
                  { id: "fa-bus-school", name: "bus-school" },
                  { id: "fa-bus-school", name: "bus-school" },
                  { id: "fa-bus-school", name: "bus-school" },
                  { id: "fa-bus-school", name: "bus-school" },
                  { id: "fa-bus-school", name: "bus-school" },
                ],
              },
              {
                type: "checkbox",
                label: "Facebook",
                name: "facebook_show",
              },
            ],
            script: function () {},
          }),
        },
        {
          isComponent(el) {
            if (
              el.getAttribute &&
              el.getAttribute("data-gjs-type") == componentOptions.blocks
            ) {
              return { type: componentOptions.blocks };
            }
          },
        }
      ),

      view: defaultView.extend({
        init: function () {
          //this.listenTo(this.model, 'change:facebook_url change:facebook_show', this.updateSrc);
          this.listenTo(this.model, 'change:facebook_url', this.doStuff);
          const comps = this.model.get("components");
          // Add a basic countdown template if it's not yet initialized
          const componente = JSON.stringify(comps);
          if (!comps.length) {
            comps.reset();
            comps.add(`Ingresa tu texto aquí... ${componente}`);
          }
        },
        doStuff() {
          console.log("change");
          console.log(this.get('traits'));
        },
        events: {
          // If you want to bind the event to children elements
          // 'click .someChildrenClass': 'methodName',
          // click: "handleClick",
          // dblclick: function () {
          //   alert("Hi!");
          // },
        },

        // handleClick: function (e) {
        //   console.log(e);
        //   var comps = this.model.get("components");
        //   var url = comps.get("facebook_url");
        //   console.log(url);
        //   var facebookUrl = document.getElementsByClassName("facebook");
        //   //  facebookUrl[0].setAttribute('href', url);
        //   console.log(facebookUrl);
        // },

        onRender({ el, model }) {
          const asyncContent = model.get('facebook_url');
          console.log("el", el);
          console.log("asyncContent",asyncContent);
        }
      }),
    });
    const style = componentOptions.defaultStyle
      ? `<style>
      .${sfx} {
        text-align: center;
        font-family: Helvetica, serif;
      }
      .${sfx}-block {
        display: inline-block;
        margin: 0 10px;
        padding: 10px;
      }
      .${sfx}-digit {
        font-size: 5rem;
      }
      .${sfx}-endtext {
        font-size: 5rem;
      }
      .${sfx}-cont,
      .${sfx}-block {
        display: inline-block;
      }
    </style>`
      : "";

    var blockManager = editor.BlockManager;
    blockManager.add(componentOptions.blocks, {
      label: "Plugin Example",
      category: "Extra",
      content: {
        content: `${style}`,
        editable: !1,
        droppable: !0,
        type: componentOptions.blocks,
        style: {
          width: "100%",
          "text-align": "center",
        },
      },
      attributes: { class: "fa fa-cogs" },
    });

    // Global hooks
editor.on(`component:create`, model => console.log('Global hook: component:create', model.get('type')));
editor.on(`component:mount`, model => console.log('Global hook: component:mount', model.get('type')));
editor.on(`component:update:facebook_url`, model => console.log('Global hook: component:update:facebook_url', model.get('type')));
editor.on(`component:remove`, model => console.log('Global hook: component:remove', model.get('type')));
  }
);
